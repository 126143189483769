import React, { CSSProperties } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import BotList from '../components/bot/BotList';

const style: CSSProperties = {
  padding: '24px 0',
}

function HomePage({ match }: RouteComponentProps) {
  const { uid } = match.params as any;

  return (
    <div style={style}>
      <BotList uid={uid} />
    </div>
  );
}

export default HomePage;
