import React, { useState, useEffect } from 'react';
import { Button, Card, List, Modal, PageHeader, Skeleton, Avatar, Space } from 'antd';
import { listUsers } from '../../firebase/admin';
import { Link } from 'react-router-dom';
import { EyeOutlined, FacebookFilled, GoogleSquareFilled, MailFilled, UserOutlined } from '@ant-design/icons';
import DateTime from '../../components/common/DateTime';

function AdminUsersSubPage() {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ users, setUsers ] = useState([] as any[]);

  const getUserName = (name: string, email: string) => {
    if (!name) {
      return email;
    }
    if (!email) {
      return name;
    }
    return `${name} (${email})`;
  };

  useEffect(() => {
    listUsers()
      .then(users => {
        setUsers(users);
        setIsLoading(false);
      })
      .catch(({ message }) => Modal.error({ content: message }));
  }, []);
 
  return (
    <>
      <PageHeader title={`All Users ${isLoading ? '' : `(${users.length})`}`} />
      {isLoading && <Skeleton avatar active />}
      {!isLoading && (
        <Card>
          <List dataSource={users} renderItem={user => (
            <List.Item
              key={user.uid}
              extra={
                <Link to={`/${user.uid}`} target="_blank">
                  <Button icon={<EyeOutlined />}>Immerse</Button>
                </Link>
              }
              actions={[
                <span>Created: <DateTime time={user.metadata?.creationTime} /></span>,
                <span>Signed In: <DateTime time={user.metadata?.lastSignInTime} /></span>,
              ]}
            >
              <List.Item.Meta
                avatar={user.photoURL ? <Avatar size="large" src={user.photoURL} /> : <Avatar size="large" icon={<UserOutlined />} />}
                title={getUserName(user.displayName, user.email)}
                description={(
                  <Space>
                    UID: {user.uid}
                    {user.providerData && user.providerData.map(({ providerId }: any) => {
                      if (providerId === 'password') {
                        return <MailFilled />
                      }
                      if (providerId === 'facebook.com') {
                        return <FacebookFilled />;
                      }
                      if (providerId === 'google.com') {
                        return <GoogleSquareFilled />;
                      }
                      return <></>;
                    })}
                  </Space>
                )}
              />
            </List.Item>
          )} />
        </Card>
      )}
    </>
  );
}

export default AdminUsersSubPage;
