import firebase from 'firebase/app';
import { getAuthService, getDBService, getFunctions, getAnalytics } from './';

const BOT_COLLECTION_NAME = 'bots';

export type BotEntity = {
  id: string,
  uid: string,
  name: string,
  description: string,
  type: string,
  server: string,
  authType?: string,
  username: string,
  password: string,
  createdAt: firebase.firestore.Timestamp,
  updatedAt: firebase.firestore.Timestamp,
  options?: any,
};

export function getBotList(userId?: string) {
  const db = getDBService();
  const auth = getAuthService();
  const uid = userId || auth.currentUser!.uid;

  return db.collection(BOT_COLLECTION_NAME)
    .where('uid', '==', uid)
    .get()
    .then(querySnapshot => {
      const results: BotEntity[] = [];
      querySnapshot.forEach(doc => {
        results.push({
          id: doc.id,
          ...doc.data(),
        } as BotEntity);
      });
      // sort by name ASC
      return results.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1);
    })
    .catch(error => console.log(error));
}

export function getBotById(id: string) {
  const db = getDBService();

  return db.collection(BOT_COLLECTION_NAME)
    .doc(id)
    .get()
    .then(documentSnapshot => ({
      id,
      ...documentSnapshot.data(),
    } as BotEntity))
    .catch(error => console.log(error));
}

export function createBot(bot: BotEntity) {
  const createBot = getFunctions().httpsCallable('createBot');
  getAnalytics().logEvent('add_bot');
  return createBot(bot).then(({ data }) => data);
}

export function updateBot(id: string, values: any) {
  const updateBot = getFunctions().httpsCallable('updateBot');
  getAnalytics().logEvent('update_bot');
  return updateBot({ ...values, id }).then(({ data: { result } }) => result);
}

export function getBotDetails(id: string) {
  const getBot = getFunctions().httpsCallable('getBot');
  getAnalytics().logEvent('get_bot');
  return getBot({ id }).then(({ data }) => data);
}

export function startBot(id: string, password?: string) {
  const startBot = getFunctions().httpsCallable('startBot');
  getAnalytics().logEvent('start_bot');
  return startBot({ id, password }).then(({ data: { result } }) => result);
}

export function stopBot(id: string) {
  const stopBot = getFunctions().httpsCallable('stopBot');
  getAnalytics().logEvent('stop_bot');
  return stopBot({ id }).then(({ data: { result }  }) => result);
}

export function askBot(id: string, message: string) {
  const askBot = getFunctions().httpsCallable('askBot');
  getAnalytics().logEvent('ask_bot');
  return askBot({
    id,
    message,
  }).then(({ data: { result }}) => result);
}

export function viewBotLogs(id: string) {
  const viewBotLogs = getFunctions().httpsCallable('viewBotLogs');
  getAnalytics().logEvent('view_bot_logs');
  return viewBotLogs({ id }).then(({ data }) => data);
}