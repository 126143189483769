import React, { createRef, useState } from 'react';
import { Card, Form, Space, Input, Button, Modal, Spin, Select } from 'antd';
import { BotEntity, updateBot } from '../../firebase/bot';
import BotInfoHeader from '../../components/bot/BotInfoHeader';
import CardContainer from '../../components/common/CardContainer';
import BotOptionsCard from '../../components/bot/BotOptionsCard';
import { FormInstance } from 'antd/lib/form';
import { StyleBundle } from '../../helpers/types';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

interface BotSettingsSubPageProps extends RouteComponentProps {
  bot: BotEntity,
};

const styles: StyleBundle = {
  card: {
    width: '450px',
  },
  narrowCard: {
    width: '350px',
  }
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { offset: 6, span: 18 },
  }
}

function BotSettingsSubPage({ bot, location, history }: BotSettingsSubPageProps) {
  const [ infoChanged, setInfoChanged ] = useState(false);
  const [ accountChanged, setAccountChanged ] = useState(false);
  const [ isSaving, setIsSaving ] = useState(false);

  const infoForm = createRef<FormInstance>();
  const accountForm = createRef<FormInstance>();

  const infoValues = {
    name: bot.name,
    description: bot.description,
    server: bot.server,
  };
  const accountValues = {
    authType: bot.authType === 'microsoft' ? 'microsoft' : 'mojang',
    username: bot.username,
  };
  const hasPassword = Boolean(bot.password);

  const onSaveValues = (values: any) => {
    setIsSaving(true);
    updateBot(bot.id, values)
      .then(() => {
        // FIXME: hack to reload the sub page
        history.push('/nonexist');
        history.replace(location.pathname);
      })
      .catch(({ message }) => {
        setIsSaving(false);
        Modal.error({
          content: message,
        });
      });
  };

  return (
    <>
      <BotInfoHeader bot={bot} />
      <Modal visible={isSaving} footer={null} closable={false}>
        <Space size="large">
          <Spin size="large" />
          Saving...
        </Space>
      </Modal>
      <CardContainer>
        <Card title="Basic Information" style={isMobile ? styles.narrowCard : styles.card}>
          <Form
            ref={infoForm}
            name="bot-info-form"
            initialValues={infoValues}
            onValuesChange={setInfoChanged}
            onFinish={onSaveValues}
            {...formItemLayout}
          >
            <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please name your new Bot'}]}>
              <Input placeholder="e.g. Minecraft Bot" />
            </Form.Item>
            <Form.Item name="description" label="Description" rules={[{ required: false }]}>
              <Input placeholder="e.g. My overnight AFK Bot" />
            </Form.Item>
            <Form.Item
              name="server"
              label="Server"
              extra="Which Minecraft server you would like your Bot to connect to"
              rules={[{ required: true, message: 'Please fill in the server info'}]}
            >
              <Input placeholder="e.g. play.minecloud.net" />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit" disabled={!infoChanged}>Save</Button>
            </Form.Item>
          </Form>
        </Card>

        <Card title="Minecraft Account" style={isMobile ? styles.narrowCard : styles.card}>
          <Form
            ref={accountForm}
            name="bot-account-form"
            initialValues={accountValues}
            onValuesChange={setAccountChanged}
            onFinish={onSaveValues}
            {...formItemLayout}
          >
            <Form.Item name="authType"
              label="Account Type"
              rules={[{ required: true }]}
              extra={'Wrong type will prevent Bot from starting'}
            >
              <Select>
                <Select.Option key="mojang" value="mojang">Mojang</Select.Option>
                <Select.Option key="microsoft" value="microsoft">Microsoft</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="username" label="Username" rules={[{ required: true, message: 'Please fill in your account username'}]}>
              <Input placeholder="e.g. minecraft@gmail.com" />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              extra={hasPassword ? 'Leave blank if you don\'t want to change' : 'Leave blank if you don\'t want to save it'}
            >
              <Input.Password placeholder={hasPassword ? 'Securely Saved.' : ''} />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit" disabled={!accountChanged}>Save</Button>
            </Form.Item>
          </Form>
        </Card>
        <BotOptionsCard
          bot={bot}
          style={isMobile ? styles.narrowCard : styles.card}
          onSaveValues={onSaveValues}
        />
      </CardContainer>
    </>
  );
}

export default withRouter(BotSettingsSubPage);
