import React, { useState } from 'react';
import { BotEntity } from '../../firebase/bot';
import { CSSProperties } from 'react';
import { Button, Card, Form, Select } from 'antd';
import { getRemoteConfig } from '../../firebase/index';

interface BotOptionsCardProps {
  bot: BotEntity,
  style: CSSProperties,
  onSaveValues: (values: any) => void,
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { offset: 8, span: 16 },
  }
}

function BotOptionsCard({
  bot,
  style,
  onSaveValues,
}: BotOptionsCardProps) {
  const [ infoChanged, setInfoChanged ] = useState(false);

  const optionValues = {
    house: '',
    ...bot.options,
  }
  const houses = JSON.parse(getRemoteConfig().getString('houses')) as any[];

  const handleSave = (values: any) => {
    onSaveValues({
      options: values,
    });
  };

  const getFormItems = (botType: string) => {
    switch (botType) {
      case 'afk':
        return (
          <Form.Item name="autoReconnect" label="Auto Reconnect" extra="Happens when Bot got disconnected from the server">
            <Select data-testid="auto-reconnect-config">
              <Select.Option value={0}>Never</Select.Option>
              <Select.Option value={60}>In a minute</Select.Option>
              <Select.Option value={300}>In 5 minutes</Select.Option>
              <Select.Option data-testid="auto-reconnect-option-600" value={600}>In 10 minutes</Select.Option>
            </Select>
          </Form.Item>
        );
    };
  };

  return (
    <Card title={`${bot.type.toUpperCase()} Bot Options`} style={style}>
      <Form
        name="bot-options-form"
        initialValues={optionValues}
        onValuesChange={setInfoChanged}
        onFinish={handleSave}
        {...formItemLayout}
      >
        <Form.Item name="house" label="Preferred Location" extra="Where Bot connects the server from">
          <Select data-testid="house-config">
            <Select.Option value="">Random</Select.Option>
            {houses.map(house => <Select.Option key={house.value} value={house.value}>{house.name}</Select.Option>)}
          </Select>
        </Form.Item>
        {getFormItems(bot.type)}
        <Form.Item {...tailLayout}>
          <Button data-testid="save-button" type="primary" htmlType="submit" disabled={!infoChanged}>Save</Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default BotOptionsCard;
