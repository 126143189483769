import React, { useState, useEffect } from 'react';
import { Button, Modal, PageHeader, Space, Table, Tag } from 'antd';
import { listBots } from '../../firebase/admin';
import { Link } from 'react-router-dom';
import { CheckCircleTwoTone, MediumOutlined, MinusOutlined, WindowsOutlined } from '@ant-design/icons';
import DateTime from '../../components/common/DateTime';

function AdminBotsSubPage() {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ bots, setBots ] = useState([] as any[]);

  const getOwnerShortID = (uid: string) => {
    return uid.slice(0, 2) + uid.slice(-2);
  };

  const columns = [
    { title: 'Type', dataIndex: 'type', key: 'type', render: (type: string) => <Tag>{type.toUpperCase()}</Tag> },
    { title: 'Name', dataIndex: 'name', key: 'name', render: (name: string, bot: any) => <Link to={`/bot/${bot.id}`} target="_blank">{name}</Link>},
    { title: 'Server', dataIndex: 'server', key: 'server' },
    { title: 'Account', dataIndex: 'username', key: 'username', render: (username: string, bot: any) => (
      <Space>
        {bot.authType === 'microsoft' ? <WindowsOutlined /> : <MediumOutlined />}
        {username}
      </Space>
    )},
    { title: 'Password?', dataIndex: 'password', key: 'password', render: (password: string) => password ? <CheckCircleTwoTone /> : <MinusOutlined /> },
    { title: 'Alive?', dataIndex: 'isAlive', key: 'isAlive', render: (isAlive: boolean) => isAlive ? <CheckCircleTwoTone /> : <MinusOutlined /> },
    { title: 'Instances', dataIndex: 'totalInstances', key: 'totalInstances' },
    { title: 'Owner', dataIndex: 'uid', key: 'uid', render: getOwnerShortID },
    { title: 'Created IP', dataIndex: 'ip', key: 'ip' },
    { title: 'Created Time', dataIndex: 'createdAt', key: 'createdAt', render: (time: any) => <DateTime time={time} /> },
  ];

  const loadData = () => {
    setIsLoading(true);
    listBots()
      .then((bots: any[]) => {
        setBots(bots);
        setIsLoading(false);
      })
      .catch(({ message }) => Modal.error({ content: message }));
  };

  useEffect(() => loadData(), []);

  return (
    <>
      <PageHeader title={`Recent Bots ${isLoading ? '' : `(${bots.length})`}`} extra={<Button type="primary" loading={isLoading} disabled={isLoading} onClick={loadData}>Refresh</Button> } />
      <Table
        loading={isLoading}
        size="large"
        dataSource={bots}
        columns={columns}
        pagination={{
          pageSize: 18,
        }} />
    </>
  );
}

export default AdminBotsSubPage;
