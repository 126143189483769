import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { Layout } from 'antd';
import 'antd/dist/antd.css';
import { initializeFirebase } from './firebase';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import BotPage from './pages/BotPage';
import VivoPage from './pages/dev/VivoPage';
import { AuthContext, AuthProvider } from './providers/AuthProvider';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import { StyleBundle } from './helpers/types';
import { isDev } from './helpers';
import HelpButton from './components/common/HelpButton';
import FullPageLoading from './components/common/FullPageLoading';
import AdminPage from './pages/AdminPage';

function App() {
  const [ isLoading, setIsLoading ] = useState(true);

  useEffect(() => {
    initializeFirebase().then(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <FullPageLoading />;
  }

  const styles: StyleBundle = {
    container: {
      minHeight: '100vh',
    },
    content: {
      margin: '64px 0',
    }
  }

  return (
    <AuthProvider>
      <Router>
        <Layout style={styles.container}>
          <Header />
          <Layout.Content style={styles.content}>
            <AuthContext.Consumer>
              {({ userState }) => userState ? (
                <>
                  <Switch>
                    <Route exact path="/logout" component={LogoutPage} />
                    <Route path="/bot/:id" component={BotPage} />
                    {isDev && <Route path="/vivo" component={VivoPage} />}
                    {userState.isAdmin ? [
                      <Route key="admin" path="/admin" component={AdminPage} />,
                      <Route key="home" path="/:uid?" component={HomePage} />,
                    ] : <Route path="/" component={HomePage} />}
                  </Switch>
                  <HelpButton email={userState.email} />
                </>
              ) : (
                <Switch>
                  <Route exact path="/logout" component={LogoutPage} />
                  <Route path="/" component={LoginPage} />
                </Switch>
              )}
            </AuthContext.Consumer>
          </Layout.Content>
          <Footer />
        </Layout>
      </Router>
    </AuthProvider>
  );
}

export default App;
