import React, { useEffect, useState } from 'react';
import { List, Card, Descriptions, Skeleton, Tag, PageHeader, Alert, Result } from 'antd';
import { isMobile } from 'react-device-detect';
import { getBotList, BotEntity } from '../../firebase/bot';
import { Link } from 'react-router-dom';
import { StyleBundle } from '../../helpers/types';
import BotCreationButton from './BotCreationButton';
import ImmersiveModeTag from '../common/ImmersiveModeTag';
import { getRemoteConfig } from '../../firebase';

interface BotListProps {
  uid?: string;
}

const styles: StyleBundle = {
  container: {
    width: '960px',
    margin: '0 auto',
  },
  containerMobile: {
    margin: '0 auto',
  },
  header: {
    padding: '16px 0 16px 0',
  }
};

function BotList({ uid }: BotListProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [botList, setBotList] = useState([] as BotEntity[]);
  const maxBotsPerUser = getRemoteConfig().getNumber('maxBotsPerUser');
  const noMoreBotAllowed = botList.length >= maxBotsPerUser;

  useEffect(() => {
    getBotList(uid)
      .then(botList => {
        setBotList(botList as BotEntity[]);
        setIsLoading(false);
      });
  }, [ uid ]);

  return (
    <div style={isMobile ? styles.containerMobile : styles.container}>
      {noMoreBotAllowed && <Alert message={`You are only allowed to create ${maxBotsPerUser} Bots at most.`} type="warning" />}
      <PageHeader
        style={styles.header}
        title={uid ? `All Bots from ${uid}` : 'All my Bots'}
        {...(!isLoading && botList.length > 0 ? { extra: <BotCreationButton disabled={noMoreBotAllowed} /> } : {})}
        {...(uid ? { tags: <ImmersiveModeTag /> } : {})}
      />
      {isLoading && (
        <Skeleton active />
      )}
      {!isLoading && botList.length > 0 && (
        <List data-testid="bot-list" grid={{ gutter: 16, column: 3, xs: 1, sm: 2, md: 2 }} dataSource={botList} renderItem={bot => (
          <List.Item>
            <Link to={`/bot/${bot.id}`}>
              <Card data-testid="bot-card" title={bot.name} extra={<Tag>{bot.type.toUpperCase()} Bot</Tag>}>
                <Descriptions column={1}>
                  <Descriptions.Item label="MC Server">{bot.server || '(Not Set)'}</Descriptions.Item>
                  <Descriptions.Item label="Account">{bot.username || '(Not Set)'}</Descriptions.Item>
                  <Descriptions.Item label="Created At">{bot.createdAt.toDate().toLocaleDateString()}</Descriptions.Item>
                </Descriptions>
              </Card>
            </Link>
          </List.Item>
        )} />
      )}
      {!isLoading && botList.length === 0 && (
        <Result
          data-testid="bot-empty-list"
          status="403"
          title="You don't have any Bot yet"
          subTitle={`You are allowed to create up to ${maxBotsPerUser} Bots. Get started by`}
          extra={<BotCreationButton>Create Your First Bot</BotCreationButton>}
        />
      )}
    </div>
  );
}

export default BotList;
