import React, { useState, useEffect, useCallback } from 'react';
import { Button, Card, Descriptions, Modal, Space, Spin } from 'antd';
import { BotEntity, getBotDetails, stopBot } from '../../firebase/bot';
import DateTime from '../common/DateTime';
import BotLogs, { LogEntry } from './BotLogs';
import BotStartButton from './BotStartButton';
import AuthPendingCard from './AuthPendingCard';
import { StyleBundle } from '../../helpers/types';

interface BotStatusCardProps {
  bot: BotEntity,
}

const styles: StyleBundle = {
  container: {
    display: 'flex',
    flexFlow: 'wrap',
  },
  card: {
    marginLeft: '24px',
    width: '350px',
  },
};

function BotStatusCard({ bot }: BotStatusCardProps) {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isAlive, setIsAlive ] = useState(false);
  const [ isActionRunning, setIsActionRunning ] = useState(false);
  const [ lastLogs, setLastLogs ] = useState([] as LogEntry[]);
  const [ authPending, setAuthPending ] = useState(null as any);

  const showError = (error: string) => {
    setIsActionRunning(false);
    Modal.error({
      content: error
    });
  };

  const fetchBotDetails = useCallback(() => {
    setIsLoading(true);
    getBotDetails(bot.id)
      .then(({ isAlive, lastLogs, status }) => {
        setIsAlive(isAlive);
        setLastLogs(lastLogs);
        setAuthPending(status?.authPending);
        setIsLoading(false);
      })
      .catch(({ message }) => showError(message))
      .finally(() => setIsLoading(false));
  }, [bot.id]);

  const onClickStopButton = () => {
    setIsActionRunning(true);
    stopBot(bot.id)
      .then(() => {
        fetchBotDetails();
      })
      .catch(({ message }) => showError(message))
      .finally(() => setIsActionRunning(false));
  };

  const onBotStarted = () => {
    // wait for a couple seconds. otherwise will get nothing back
    setIsLoading(true);
    setTimeout(() => fetchBotDetails(), 3000);
  };

  useEffect(() => fetchBotDetails(), [fetchBotDetails]);

  return (
    <div style={styles.container}>
      <Card title="Status" style={styles.card}>
        <Descriptions column={1}>
          <Descriptions.Item key="Created At" label="Created At"><DateTime time={bot.createdAt} /></Descriptions.Item>
          {bot.updatedAt && <Descriptions.Item key="Last Modified" label="Last Modified"><DateTime time={bot.updatedAt} /></Descriptions.Item>}
          <Descriptions.Item key="Status" label="Status">
            {isLoading ? <Spin /> : (
              isAlive ? 'Running' : 'Stopped'
            )}
          </Descriptions.Item>
          {!isLoading && (
            <Descriptions.Item key="Control" label="Control">
              {!isAlive && <BotStartButton bot={bot} onCompleted={onBotStarted} />}
              {isAlive && <Button type="primary" danger onClick={onClickStopButton} loading={isActionRunning}>Stop Bot</Button>}
            </Descriptions.Item>
          )}
        </Descriptions>
        {!isLoading && !isAlive && lastLogs.length > 0 && (
          <Space direction="vertical">
            <p>Most Recent In-Game Logs:</p>
            <BotLogs logs={lastLogs} narrow />
          </Space>
        )}
      </Card>
      {authPending && <AuthPendingCard style={styles.card} authPending={authPending} onRefresh={fetchBotDetails} />}
    </div>
  );
}

export default BotStatusCard;
