import React, { useState, useEffect, useCallback } from 'react';
import { Button, Modal, Skeleton, Card, Progress, Tooltip, Space, Typography, Descriptions, Result } from 'antd';
import { BotEntity, getBotDetails, stopBot } from '../../firebase/bot';
import BotInfoHeader from '../../components/bot/BotInfoHeader';
import PlayerList, { Player } from '../../components/minecraft/PlayerList';
import { StyleBundle } from '../../helpers/types';
import CardContainer from '../../components/common/CardContainer';
import InventoryView from '../../components/minecraft/InventoryView';
import { getAnalytics } from '../../firebase';
import BotStartButton from '../../components/bot/BotStartButton';
import AuthPendingCard from '../../components/bot/AuthPendingCard';

interface BotDashboardSubPageProps {
  bot: BotEntity,
}

const styles: StyleBundle = {
  playerList: {
    width: '320px',
    height: '500px',
    overflow: 'scroll',
  },
  card: {
    width: '380px',
  },
};

function BotDashboardSubPage({ bot }: BotDashboardSubPageProps) {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isAlive, setIsAlive ] = useState(false);
  const [ isActionRunning, setIsActionRunning ] = useState(false);
  const [ players, setPlayers ] = useState([] as Player[]);
  const [ time, setTime ] = useState({} as any);
  const [ inventory, setInventory ] = useState({} as any);
  const [ position, setPosition ] = useState({} as any);
  const [ status, setStatus ] = useState({} as any);
  const [ misc, setMisc ] = useState({} as any);
  const [ authPending, setAuthPending ] = useState(null as any);

  const showError = (error: string) => {
    setIsActionRunning(false);
    Modal.error({
      content: error
    });
  };

  const getBotStatus = useCallback(() => {
    setIsLoading(true);
    getBotDetails(bot.id)
      .then(({ isAlive, status }) => {
        if (isAlive) {
          if (status.authPending) {
            setAuthPending(status.authPending);
          } else {
            setPlayers(status.players);
            setTime(status.time);
            setInventory(status.inventory);
            setPosition({
              dimension: status.game.dimension,
              x: status.x,
              y: status.y,
              z: status.z,
              pitch: status.pitch,
              yaw: status.yaw,
            });
            setStatus({
              experience: status.experience,
              health: status.health,
              food: status.food,
              foodSaturation: status.foodSaturation,
            });
            setMisc(status.game);
          }
        }
        setIsAlive(isAlive);
      })
      .catch(error => Modal.error({ content: error }))
      .finally(() => setIsLoading(false));
  }, [ bot ]);

  const onBotStarted = () => {
    // wait for a couple seconds. otherwise will get nothing back
    setIsLoading(true);
    setTimeout(() => getBotStatus(), 3000);
  };

  const onClickStopButton = () => {
    setIsActionRunning(true);
    stopBot(bot.id)
      .then(() => getBotStatus())
      .catch(({ message }) => showError(message))
      .finally(() => setIsActionRunning(false));
  };

  useEffect(() => {
    getAnalytics().logEvent('view_bot_dashboard');
    getBotStatus();
  }, [ bot, getBotStatus ]);

  return (
    <div>
      <BotInfoHeader bot={bot} actions={[
        <Button type="primary" key="refresh" hidden={!isAlive} disabled={isActionRunning} loading={isLoading} onClick={getBotStatus}>Refresh</Button>,
        <Button type="primary" key="stop" danger hidden={!isAlive} disabled={isLoading} loading={isActionRunning} onClick={onClickStopButton}>Stop Bot</Button>,
      ]} />
      {isLoading && <Skeleton active avatar />}
      {!isLoading && !isAlive && (
        <Result
          data-testid="dashboard-empty"
          status="500"
          title="You Bot is not running"
          subTitle="To view online players and inventory, you need to run your Bot"
          extra={<BotStartButton bot={bot} onCompleted={onBotStarted}>Start Bot Now</BotStartButton>}
        />
      )}
      {!isLoading && isAlive && authPending && (
        <CardContainer>
          <AuthPendingCard style={styles.card} authPending={authPending} onRefresh={getBotStatus} />
        </CardContainer>
      )}
      {!isLoading && isAlive && players.length > 0 && (
        <CardContainer>
          <Card size="small" title={`Online Players (${players.length}/${misc.maxPlayers})`}>
            <PlayerList style={styles.playerList} players={players} />
          </Card>
          <Card size="small" title="Status & Inventory" style={styles.card}>
            <Space direction="vertical" size="middle">
              <Space direction="vertical">
                <Space size="large">
                  <Tooltip title={`XP Points: ${status.experience.points}`}>
                    <Progress
                      type="circle"
                      percent={status.experience.progress * 100}
                      strokeColor="#52c41a"
                      format={() => `Level ${status.experience.level}`}
                    />
                  </Tooltip>
                  <Descriptions column={1}>
                    <Descriptions.Item key="server" label="Server">
                      <Tooltip title={misc.serverBrand}>{bot.server}</Tooltip>
                    </Descriptions.Item>
                    <Descriptions.Item key="game mode" label="Game Mode">{misc.gameMode}</Descriptions.Item>
                    <Descriptions.Item key="difficulty" label="Difficulty">{misc.difficulty}</Descriptions.Item>
                  </Descriptions>
                </Space>
                <Tooltip title={`Food Saturation: ${status.foodSaturation}`}>
                  <Progress showInfo={false} percent={status.foodSaturation * 100 / 20} />
                </Tooltip>
                <Space>
                  <Tooltip title={`Health: ${status.health}`}>
                    <Progress showInfo={false} percent={status.health * 100 / 20} steps={10} strokeColor="#ff4d4f" />
                  </Tooltip>
                  <Tooltip title={`Hunger: ${status.food}`}>
                    <Progress showInfo={false} percent={status.food * 100 / 20} steps={10} />
                  </Tooltip>
                </Space>
              </Space>
              <InventoryView slots={inventory.slots} />
            </Space>
          </Card>
          <Space direction="vertical" size="large">
            <Card size="small" title="Position">
              <Typography.Paragraph>
                Dimension: {position.dimension}
              </Typography.Paragraph>
              <Typography.Paragraph>
                Position: ({position.x?.toFixed(2)}, {position.y?.toFixed(2).toString()}, {position.z?.toFixed(2)})
              </Typography.Paragraph>
              <Typography.Paragraph>
                Pitch: {position.pitch}
              </Typography.Paragraph>
              <Typography.Paragraph>
                Yaw: {position.yaw}
              </Typography.Paragraph>
            </Card>
            <Card size="small" title="Time">
              <Typography.Paragraph>
                Age / Time: {time.age} / {time.time}
              </Typography.Paragraph>
              <Typography.Paragraph>
                Time / Day: {time.timeOfDay} / {time.day}
              </Typography.Paragraph>
              <Typography.Paragraph>
                {time.isDay ? 'Daytime' : 'Nighttime'} and {time.isRaining ? 'Raining' : 'Sunny'}.
              </Typography.Paragraph>
              <Typography.Paragraph>
                Moon Phase: {time.moonPhase}
              </Typography.Paragraph>
            </Card>
          </Space>
        </CardContainer>
      )}
    </div>
  );
}

export default BotDashboardSubPage;
