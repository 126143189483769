import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../logo.svg';
import { StyleBundle } from '../../helpers/types';

const styles: StyleBundle = {
  logo: {
    width: '150px',
  },

  image: {
    height: '36px',
    verticalAlign: 'text-bottom',
  },
  
  name: {
    paddingLeft: '12px',
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#fff',
  }  
}

function Logo() {
  return (
    <Link to="/">
      <div style={styles.logo}>
        <img style={styles.image} data-testid="logo" src={logo} alt="McBot Logo" />
        <span style={styles.name}>McBot</span>
      </div>
    </Link>
  );
}

export default Logo;
