import React, { Component, createRef, ReactNode } from 'react';
import { Button, Form, Input, Modal, Select, Steps, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { BotEntity, createBot } from '../../firebase/bot';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormInstance } from 'antd/lib/form';
import { CSSProperties } from 'react';
import { getRemoteConfig } from '../../firebase';
import { getAnalytics } from '../../firebase';

interface BotCreationButtonProps extends RouteComponentProps {
  disabled?: boolean;
  children?: ReactNode;
};

interface BotCreationButtonState {
  modalVisible: boolean;
  requesting: boolean;
  currentStep: number;
  formValues: any;
}

const formStyle: CSSProperties = {
  marginTop: '24px',
};

class BotCreationButton extends Component<BotCreationButtonProps, BotCreationButtonState> {
  state = {
    modalVisible: false,
    requesting: false,
    currentStep: 0,
    formValues: {
      type: 'afk',
      authType: 'mojang',
    },
  };

  formRef = createRef<FormInstance>();

  showModal = () => {
    getAnalytics().logEvent('create_bot_start');
    this.setState({
      modalVisible: true,
    });
  };

  showError = (error: string) => {
    getAnalytics().logEvent('create_bot_show_error');
    this.setState({
      requesting: false,
    });
    Modal.error({
      content: error
    });
  };

  validateServer = (server: string) => {
    this.setState({
      requesting: true,
    });
    return new Promise((resolve, reject) => {
      fetch(`https://api.mcsrvstat.us/2/${server}`)
        .then(response => response.json())
        .then(data => {
          const { ip, port, online, protocol, version } = data;
          if (!ip || !port || !online || !protocol) {
            getAnalytics().logEvent('create_bot_validate_server_failed');
            reject(`${server} is not a valid Minecraft server IP or it's not running.
            Please make sure you have the correct server IP filled in or your server
            is running now.`)
          }
          getAnalytics().logEvent('create_bot_validate_server_success');
          resolve(version);
        })
        // if failed to validate it, then just let it go...
        .catch(() => {
          getAnalytics().logEvent('create_bot_validate_server_error');
          resolve('');
        })
        .finally(() => this.setState({
          requesting: false,
        }));
    });
  };

  validateAccount = (username: string, password: string) => {
    this.setState({
      requesting: true,
    });
    return new Promise((resolve, reject) => {
      fetch(`https://authserver.mojang.com/authenticate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password }),
      })
        .then(response => response.json())
        .then(data => {
          const { accessToken, clientToken } = data;
          if (!accessToken || !clientToken) {
            reject('Your Minecraft username or password is not correct. Please fill in valid Minecraft username and password.');
          }
          resolve(accessToken);
        })
        // if failed to validate it, then just let it go...
        .catch(resolve)
        .finally(() => this.setState({
          requesting: false,
        }));
    });
  };

  onOK = () => {
    this.formRef.current
      ?.validateFields()
      .then(/*async*/ lastValues => {
        getAnalytics().logEvent('create_bot_finish');
        // if (lastValues.username && lastValues.password) {
        //   try {
        //     await this.validateAccount(lastValues.username, lastValues.password);
        //   } catch (error) {
        //     this.showError(error);
        //     return;
        //   }
        // }
        const values = {
          ...this.state.formValues,
          ...lastValues,
        };
        this.setState({
          requesting: true,
        });
        createBot(values as BotEntity)
          .then(data => {
            const botId = data.id;
            if (!botId) {
              this.showError('Cannot get ID from the created Bot');
            } else {
              // redirect to BotPage
              const { history } = this.props;
              history.push(`/bot/${botId}`);
            }
          })
          .catch(({ message }) => this.showError(message));
      })
      .catch(() => {});
  };

  onCancel = () => {
    getAnalytics().logEvent('create_bot_cancel');
    const { requesting } = this.state;
  
    if (!requesting) {
      this.setState({
        modalVisible: false,
      });
    }
  };

  onPrevious = () => {
    this.setState(({ currentStep }) => ({ currentStep: currentStep - 1}));
  };
  
  onNext = () => {
    const moveToNextStep = (values: any) => this.setState(({ currentStep, formValues }) => ({
      currentStep: currentStep + 1,
      formValues: {
        ...formValues,
        ...values,
      }
    }));
    this.formRef.current
      ?.validateFields()
      .then(values => {
        const { currentStep } = this.state;
        const needValidateMCServer = getRemoteConfig().getBoolean('ifValidateMCServer');
        if (currentStep === 1 && needValidateMCServer) {
          this.validateServer(values.server)
            .then(() => moveToNextStep(values))
            .catch(this.showError);
        } else {
          moveToNextStep(values);
        }
      })
      .catch(() => {});
  };

  render() {
    const { disabled, children = 'Create a Bot' } = this.props;
    const { formValues, modalVisible, requesting, currentStep } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const botTypes = JSON.parse(getRemoteConfig().getString('botTypes')) as any[];

    return (
      <>
        <Button
          data-testid="bot-creation-button"
          type="primary"
          icon={<PlusOutlined />}
          disabled={disabled}
          onClick={this.showModal}
        >
          {children}
        </Button>
        <Modal
          visible={modalVisible}
          onOk={this.onOK}
          onCancel={this.onCancel}
          footer={(
          <>
            {currentStep > 0 && <Button key="previous" disabled={requesting} onClick={this.onPrevious}>Previous</Button>}
            {currentStep < 2 && <Button type="primary" key="next" loading={requesting} disabled={requesting} onClick={this.onNext}>Next</Button>}
            {currentStep === 2 && <Button key="ok" type="primary" loading={requesting} onClick={this.onOK}>Create</Button>}
          </>)}
          title="Create a Bot"
        >
          <Steps current={currentStep}>
            <Steps.Step key={0} title="Bot Type" />
            <Steps.Step key={1} title="Information" />
            <Steps.Step key={2} title="Minecraft Account" />
          </Steps>
          <Form data-testid="bot-creation-form" ref={this.formRef} name="bot-creation-form" style={formStyle} initialValues={formValues} {...formItemLayout}>
            {currentStep === 0 && (<>
              <Typography.Paragraph>
                Please choose what kind of Bot you want to create
              </Typography.Paragraph>
              <Form.Item name="type" label="Type" rules={[{ required: true, message: 'Please select a type for your new Bot'}]}>
                <Select>
                  {botTypes.map(botType => (<Select.Option key={botType.value} value={botType.value}>{botType.name}</Select.Option>))}
                </Select>
              </Form.Item>
            </>)}
            {currentStep === 1 && (<>
              <Typography.Paragraph>
                Please fill in the following necessary information
              </Typography.Paragraph>
              <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please name your new Bot'}]}>
                <Input placeholder="e.g. Minecraft Bot" />
              </Form.Item>
              <Form.Item name="description" label="Description" rules={[{ required: false }]}>
                <Input placeholder="e.g. My overnight AFK Bot" />
              </Form.Item>
              <Form.Item
                name="server"
                label="Server"
                extra="Which Minecraft server you would like your Bot to connect to"
                rules={[{ required: true, message: 'Please fill in a valid Minecraft server IP'}]}
              >
                <Input placeholder="e.g. play.minecloud.net" />
              </Form.Item>
            </>)}
            {currentStep === 2 && (<>
              <Typography.Paragraph>
                Your Bot needs a Minecraft account to run. Please fill in valid Minecraft username and password or other account you want your Bot to use.
                (Learn more about why and how we treat the password <Typography.Link target="_blank" href="https://www.mcbot.org/update/password-optional/">here</Typography.Link>)
              </Typography.Paragraph>
              <Form.Item name="authType"
                label="Account Type"
                rules={[{ required: true, /*validator: (_, value) => value === 'mojang' ? Promise.resolve() : Promise.reject('Sorry, we are addressing some issues and temporarily disabled Microsoft login for now')*/ }]}
                extra={'Wrong type will prevent Bot from starting'}
              >
                <Select>
                  <Select.Option key="mojang" value="mojang">Mojang</Select.Option>
                  <Select.Option key="microsoft" value="microsoft">Microsoft</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="username" label="Username" rules={[{ required: true, message: 'Please fill in your account username'}]}>
                <Input placeholder="e.g. minecraft@gmail.com" />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                extra={'Leave blank if you don\'t want to save it now'}
              >
                <Input.Password />
              </Form.Item>
            </>)}
          </Form>
        </Modal>
      </>
    );
  }
}

export default withRouter(BotCreationButton);
