import React, { CSSProperties } from 'react';
import { Divider, Layout, Space, Typography } from 'antd';
import { getRemoteConfig } from '../../firebase';

const style: CSSProperties = {
  textAlign: 'center',
  // position: 'fixed',
  // bottom: 0,
  width: '100%',
}

function Footer() {
  const year = new Date().getFullYear();
  const links = JSON.parse(getRemoteConfig().getString('footerLinks')) as any[];

  return (
    <Layout.Footer style={style}>
      <Space size="large">
        <span>&copy; {year} McBot.</span>
        <Space split={<Divider type="vertical" />}>
          {links.map(link => (
            <Typography.Link key={link.title} href={link.url} target="_blank">{link.title}</Typography.Link>
          ))}
        </Space>
      </Space>
    </Layout.Footer>
  );
}

export default Footer;
