import React from 'react';
import { StyledFirebaseAuth } from 'react-firebaseui';
import firebase from 'firebase/app';
import { getAuthService } from '../firebase';
import { Alert, Space } from 'antd';
import { isMobile } from 'react-device-detect';
import { getRemoteConfig } from '../firebase';
import { StyleBundle } from '../helpers/types';

import '../firebaseui-styling.global.css';
import logo from '../logo.svg';

const styles: StyleBundle = {
  container: {
    width: '100%',
    margin: '0 auto',
  },
  logo: {
    width: '100px',
    height: '100px',
  },
  alert: {
    width: isMobile ? '100%' : '500px',
  },
  form: {
    width: '360px',
  }
};

function LoginPage() {
  const notice = getRemoteConfig().getString('signInNotice');
  const auth = getAuthService();
  const callbacks = {
    signInSuccessWithAuthResult: () => false // no redirect
  };

  return (
    <Space direction="vertical" align="center" style={styles.container} size={32}>
      <img data-testid="logo" style={styles.logo} src={logo} alt="McBot Logo" />
      {notice && (
        <Alert type="info" style={styles.alert} message={notice} />
      )}
      <div style={styles.form}>
        <StyledFirebaseAuth uiConfig={{
          signInOptions: [
            {
              provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
              signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
              emailLinkSignIn: () => ({
                url: '', // current page
                handleCodeInApp: true,
              }),
            },
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
          ],
          callbacks,
        }} firebaseAuth={auth} />
      </div>
    </Space>
  );
}

export default LoginPage;
