import React from 'react';
import { Avatar, Badge, List, Tooltip } from 'antd';
import { CSSProperties } from 'react';
import AnsiText from '../common/AnsiText';

export interface Player {
  username: string;
  displayName: string;
  uuid: string;
  isNearby?: boolean;
}

interface PlayerListProps {
  players: Player[];
  style?: CSSProperties;
}

const style: CSSProperties = {
  backgroundColor: '#001529',
  padding: '16px',
};

function PlayerList({ players, style: customStyle }: PlayerListProps) {
  return (
    <List
      style={{
        ...style,
        ...customStyle,
      }}
      size="small"
      split={false}
      dataSource={players}
      renderItem={(player: Player) => (
        <List.Item>
          <List.Item.Meta
            key={player.uuid}
            avatar={
              <Badge dot={player.isNearby}>
                <Avatar size="small" shape="square" src={`https://crafatar.com/avatars/${player.uuid}.png`} />
              </Badge>}
            title={
              <Tooltip title={player.username}>
                <AnsiText text={player.displayName} />
              </Tooltip>
            }
          />
        </List.Item>
      )}
    />
  );
}

export default PlayerList;
