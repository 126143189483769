import { createRef, ReactNode, useState } from 'react';
import { Button, Form, Input, Modal, Typography } from 'antd';
import { BotEntity, startBot } from '../../firebase/bot';
import { FormInstance } from 'antd/lib/form';

interface BotStartButtonProps {
  bot: BotEntity
  hidden?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  onCompleted?: () => void;
}

export default function BotStartButton({
  bot,
  hidden = false,
  disabled = false,
  children = 'Start Bot',
  onCompleted = () => {},
}: BotStartButtonProps) {
  const [ inputVisible, setInputVisible ] = useState(false);
  const [ isActionRunning, setIsActionRunning ] = useState(false);
  const inputForm = createRef<FormInstance>();

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const startingBot = (password?: string) => {
    setIsActionRunning(true);
    startBot(bot.id, password)
      .then(() => onCompleted())
      .catch(({ message }) => Modal.error({ content: message }))
      .finally(() => setIsActionRunning(false));
  };
  const onClickStartButton = () => {
    if (bot.password) {
      startingBot();
    } else {
      setInputVisible(true);
    }
  }
  const validateInput = () => {
    inputForm.current
      ?.validateFields()
      .then(({ password }) => {
        inputForm.current!.resetFields();
        setInputVisible(false);
        startingBot(password);
      })
      .catch(() => {});
  }

  return (
    <>
      <Button type="primary" hidden={hidden} disabled={disabled} onClick={onClickStartButton} loading={isActionRunning}>{children}</Button>
      <Modal
        visible={inputVisible}
        title="Minecraft Account Required"
        okText="Start Bot"
        onCancel={() => setInputVisible(false)}
        onOk={validateInput}
      >
        <Form
          ref={inputForm}
          name="bot-password-form"
          {...formItemLayout}
        >
          <Typography.Paragraph>
            Your Bot needs a Minecraft account to run. Since the password is not saved, 
            you need to provide the password to the account 
            <Typography.Text code>{bot.username}</Typography.Text>
            every time you start the Bot.
            Password here will not be saved for the next use.
            Learn more about it <a target="_blank" rel="noreferrer" href="https://www.mcbot.org/update/password-optional/">here</a>.
          </Typography.Paragraph>
          <Form.Item
            name="password"
            label="Password"
            extra="For one-time use only and will not be saved"
            rules={[{ required: true, message: 'Please fill in the Minecraft password'}]}
          >
            <Input.Password data-testid="password-input" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}