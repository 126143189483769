import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/remote-config';
import { isDev } from '../helpers/index';

let firebaseInstance: firebase.app.App;

export async function initializeFirebase() {
  if (!firebaseInstance) {
    let firebaseConfig;
    if (process.env.NODE_ENV === 'production') {
      // load firebase config from firebase hosting
      const response = await fetch('/__/firebase/init.json');
      firebaseConfig = await response.json();
    } else {
      // load firebase config from ENV
      firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
      };
    }
    firebaseInstance = firebase.initializeApp(firebaseConfig);
    // disable analytics for dev
    firebase.analytics().setAnalyticsCollectionEnabled(!isDev);
    // fetch remote configs
    firebase.remoteConfig().settings.minimumFetchIntervalMillis = 3600000; // 1 hour
    await firebase.remoteConfig().fetchAndActivate();
  }
}

export const getAuthService = () => firebaseInstance.auth();

export const getDBService = () => firebaseInstance.firestore();

export const getFunctions = () => firebaseInstance.functions();

export const getRemoteConfig = () => firebaseInstance.remoteConfig();

export const getAnalytics = () => firebaseInstance.analytics();
