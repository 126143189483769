import React, { useEffect } from 'react';
import { getRemoteConfig } from '../../firebase';
import { useScript } from '../../helpers';

declare global {
  var fwSettings: any;
  var FreshworksWidget: any;
}

interface HelpButtonProps {
  email?: string;
}

function HelpButton({ email }: HelpButtonProps) {
  const widgetId = getRemoteConfig().getNumber('helpWidgetId');
  const status = useScript(widgetId ? `https://widget.freshworks.com/widgets/${widgetId}.js` : '');

  useEffect(() => {
    if (status === 'loading') {
      window.fwSettings = {
        'widget_id': widgetId,
      };
    } else if (status === 'ready') {
      window.FreshworksWidget || function() {
        if ('function' != typeof window.FreshworksWidget) {
          let n: any = function() {
            n['q'].push(arguments)
          };
          n['q'] = []; window.FreshworksWidget = n
        }
      }();

      window.FreshworksWidget('show', 'launcher');

      if (email) {
        // prefill email. have to fill name as well, otherwise it does not work
        window.FreshworksWidget('identify', 'ticketForm', {
          email,
        });
        // hide unnecessary field
        window.FreshworksWidget('hide', 'ticketForm', [
          'email',
        ]);
      }
    }

    return () => {
      window.FreshworksWidget?.('hide', 'launcher');
    };
  }, [ email, status, widgetId ]);

  return <></>;
}

export default HelpButton;
