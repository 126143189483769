import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import FullPageLoading from '../components/common/FullPageLoading';
import { AuthContext } from '../providers/AuthProvider';

function LogoutPage({ history }: RouteComponentProps) {
  return (
    <AuthContext.Consumer>
      {({ signOut }) => {
        signOut()?.then(() => {
          // delayed redirecting to prevent exceptions
          setImmediate(() => history.push('/'));
        });
        return <FullPageLoading />;
      }}
    </AuthContext.Consumer>
  );
}

export default LogoutPage;
