import { Button, Card, Modal, PageHeader, Skeleton, Space, Statistic } from 'antd';
import React, { useState, useEffect } from 'react';
import { getStatistics } from '../../firebase/admin';
import { camelToTitle } from '../../helpers';

function AdminOverviewSubPage() {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ statistics, setStatistics ] = useState({} as any);

  const loadData = () => {
    setIsLoading(true);
    getStatistics()
      .then(statistics => {
        setStatistics(statistics);
        setIsLoading(false);
      })
      .catch(({ message }) => Modal.error({ content: message }));
  };

  useEffect(() => loadData(), []);

  return (
    <>
      <PageHeader title="Overview" extra={<Button type="primary" loading={isLoading} disabled={isLoading} onClick={loadData}>Refresh</Button> } />
      {isLoading && <Skeleton active />}
      {!isLoading && (
        <Space size="large" wrap>
          {Object.keys(statistics).map(key => <Card key={key}><Statistic title={camelToTitle(key)} value={statistics[key]}></Statistic></Card>)}
        </Space>
      )}
    </>
  );
}

export default AdminOverviewSubPage;
