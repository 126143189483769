import React, { useState, useEffect } from 'react';
import { Layout, Menu, Skeleton } from 'antd';
import { StyleBundle } from '../helpers/types';
import { BotEntity, getBotById } from '../firebase/bot';
import { Link, Route, useParams, RouteComponentProps } from 'react-router-dom';
import { DashboardOutlined, HistoryOutlined, HomeOutlined, MessageOutlined, SettingOutlined } from '@ant-design/icons';
import BotOverviewSubPage from './bot/BotOverviewSubPage';
import BotMessagesSubPage from './bot/BotMessagesSubPage';
import BotSettingsSubPage from './bot/BotSettingsSubPage';
import BotDashboardSubPage from './bot/BotDashboardSubPage';
import BotHistorySubPage from './bot/BotHistorySubPage';

const styles: StyleBundle = {
  container: {
  },
  content: {
    marginLeft: '200px',
    padding: '24px',
  },
  contentNarrow: {
    marginLeft: 0,
    padding: '8px',
  },
  subPage: {
    padding: '24px',
  },
  sider: {
    background: '#fff',
    height: '100vh',
    position: 'fixed',
    left: 0,
    zIndex: 2,
  },
  trigger: {
    top: 'auto',
    bottom: '200px',
  },
  menu: {
    paddingTop: '24px',
    borderRight: 0,
  },
};

function BotPage({ location, match }: RouteComponentProps) {
  const [isNarrow, setIsNarrow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [bot, setBot] = useState({} as BotEntity);
  const { id } = useParams<{ id: string }>();
  
  const selectedRoute = () => {
    const parts = location.pathname.match(/\/bot\/[^/]+\/(.+)/i) || ['', 'overview'];
    return [ parts[1] ];
  };

  useEffect(() => {
    getBotById(id)
      .then(bot => {
        setBot(bot as BotEntity);
        setIsLoading(false);
      });
  }, [id]);

  return (
    <Layout style={styles.container}>
      <Layout.Sider style={styles.sider} breakpoint="md" collapsedWidth="0" onBreakpoint={setIsNarrow} zeroWidthTriggerStyle={styles.trigger}>
        <Menu style={styles.menu} mode="inline" selectedKeys={selectedRoute()}>
          <Menu.Item key="overview" icon={<HomeOutlined />}>
            <Link to={`${match?.url}`}>Overview</Link>
          </Menu.Item>
          <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
            <Link to={`${match?.url}/dashboard`}>Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="message" icon={<MessageOutlined />}>
            <Link to={`${match?.url}/message`}>Messages</Link>
          </Menu.Item>
          <Menu.Item key="history" icon={<HistoryOutlined />}>
            <Link to={`${match?.url}/history`}>History</Link>
          </Menu.Item>
          <Menu.Item key="config" icon={<SettingOutlined />}>
            <Link to={`${match?.url}/config`}>Settings</Link>
          </Menu.Item>
        </Menu>
      </Layout.Sider>
      <Layout.Content style={isNarrow ? styles.contentNarrow : styles.content}>
        {isLoading && <Skeleton active />}
        {!isLoading && [
          <Route key="overview" exact path={`${match?.path}/`}>
            <BotOverviewSubPage bot={bot} />
          </Route>,
          <Route key="dashboard" exact path={`${match?.path}/dashboard`}>
            <BotDashboardSubPage bot={bot} />
          </Route>,
          <Route key="message" exact path={`${match?.path}/message`}>
            <BotMessagesSubPage bot={bot} />
          </Route>,
          <Route key="history" exact path={`${match?.path}/history`}>
            <BotHistorySubPage bot={bot} />
          </Route>,
          <Route key="config" exact path={`${match?.path}/config`}>
            <BotSettingsSubPage bot={bot} />
          </Route>,
        ]}
      </Layout.Content>
    </Layout>
  );
}

export default BotPage;
