import React, { useState, useEffect } from 'react';
import { Empty, Input, Modal, Skeleton, Space, Switch, Timeline } from 'antd';
import { askBot, BotEntity, getBotDetails } from '../../firebase/bot';
import { StyleBundle } from '../../helpers/types';
import BotInfoHeader from '../../components/bot/BotInfoHeader';
import { isMobile } from 'react-device-detect';
import AnsiText from '../../components/common/AnsiText';
import { getAnalytics } from '../../firebase';

interface BotMessagesSubPageProps {
  bot: BotEntity,
}

interface MessageEntry {
  timestamp: string,
  message: string,
  ansi?: string,
}

const styles: StyleBundle = {
  timeline: {
    paddingTop: '24px',
    backgroundColor: 'rgba(0, 0, 0, .9)',
    color: 'white',
  }
};

function BotMessagesSubPage({ bot }: BotMessagesSubPageProps) {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isAlive, setIsAlive ] = useState(false);
  const [ autoUpdate, setAutoUpdate ] = useState(false);
  const [ messages, setMessages ] = useState([] as MessageEntry[]);
  const [ isActionRunning, setIsActionRunning ] = useState(false);
  const [ message, setMessage ] = useState('');

  const showError = (error: string) => {
    setIsActionRunning(false);
    Modal.error({
      content: error
    });
  }

  const onSendMessage = () => {
    if (message) {
      const msg = message;
      setMessage('');
      setIsActionRunning(true);
      askBot(bot.id, msg)
        .then(() => {
          setIsActionRunning(false);
        })
        .catch(({ message }) => showError(message));
    }
  };

  useEffect(() => {
    getAnalytics().logEvent('view_bot_messages');

    const getMessages = () => {
      getBotDetails(bot.id)
        .then(({ isAlive, messages }) => {
          if (isAlive) {
            setMessages(messages);
            setIsAlive(true);
          }
          setIsLoading(false);
        })
        .catch(error => console.error(error));
    };

    // run once at first
    getMessages();

    // set interval to update every 5 seconds
    const intervalId = setInterval(() => {
      if (isLoading || !autoUpdate) {
        return;
      }
      getMessages();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [bot, isLoading, autoUpdate]);

  const body = isAlive ? (
    <>
      <Timeline reverse style={styles.timeline}>
        {messages.map(entry => (
          <Timeline.Item>
            <Space size="middle">
              {!isMobile && new Date(entry.timestamp).toLocaleString()}
              <AnsiText text={(entry.ansi || entry.message) as string} />
            </Space>
          </Timeline.Item>
        ))}
      </Timeline>
      <Input.Search
        placeholder="input message text"
        allowClear
        enterButton="Send"
        onSearch={onSendMessage}
        value={message}
        onChange={({ target: { value }}) => setMessage(value)}
        loading={isActionRunning}
      />
    </>
  ) : <Empty />;

  return (
    <div>
      <BotInfoHeader bot={bot} actions={(
        <Space>
          <span>Auto Update:</span>
          <Switch onChange={setAutoUpdate} />
        </Space>
      )}/>
      {isLoading ? <Skeleton active /> : body}
    </div>
  );
}

export default BotMessagesSubPage;
