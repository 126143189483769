import React, { useState, useEffect } from 'react';
import { Button, List, Modal, PageHeader, Skeleton, Avatar, Statistic, Collapse } from 'antd';
import { listInstances } from '../../firebase/admin';
import { Link } from 'react-router-dom';
import { EyeOutlined, RobotFilled } from '@ant-design/icons';
import DateTime from '../../components/common/DateTime';

function AdminInstancesSubPage() {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ instances, setInstances ] = useState({} as {[key: string]: any[]});

  useEffect(() => {
    listInstances()
      .then((instances: any[]) => {
        // group by instance.bot
        setInstances(instances.reduce((result: {[key: string]: any[]}, instance) => {
          (result[instance.bot] = result[instance.bot] || []).push(instance);
          return result;
        }, {}));
        setIsLoading(false);
      })
      .catch(({ message }) => Modal.error({ content: message }));
  }, []);
 
  return (
    <>
      <PageHeader title="Recent Instances" />
      {isLoading && <Skeleton avatar active />}
      {!isLoading && (
        <Collapse defaultActiveKey={Object.keys(instances)}>
          {Object.keys(instances).map(bot => (
            <Collapse.Panel
              header={`Bot: ${bot}`}
              key={bot}
              extra={
                <Link data-testid="view-bot-link" to={`/bot/${bot}`} target="_blank">
                  <Button size="small" icon={<EyeOutlined />}>View Bot</Button>
                </Link>
              }>
              <List dataSource={instances[bot]} renderItem={instance => (
                <List.Item
                  key={instance.id}
                  actions={[
                    ...(instance.updatedAt ? [<Statistic title="Duration" value={(instance.updatedAt?._seconds - instance.createdAt._seconds) / 60} precision={1} suffix="min" />] : []),
                    <Statistic title="House" value={instance.house} />,
                    ...(instance.liveId ? [<Statistic title="Live ID" value={instance.liveId} />] : []),
                  ]}
                >
                  <List.Item.Meta
                    avatar={<Avatar style={{
                      ...(instance.isAlive ? { backgroundColor: '#52c41a' } : (
                        instance.liveId ? {} : { backgroundColor: '#ff4d4f' }))
                    }} icon={<RobotFilled />} />}
                    title={instance.id}
                    description={<DateTime time={instance.createdAt} />}
                  />
                </List.Item>
              )} />
            </Collapse.Panel>
          ))}
        </Collapse>
      )}
    </>
  );
}

export default AdminInstancesSubPage;
