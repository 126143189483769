import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { StyleBundle } from '../helpers/types';
import { Link, Route, RouteComponentProps } from 'react-router-dom';
import { DashboardOutlined, HomeOutlined, RobotOutlined, TeamOutlined, UnorderedListOutlined } from '@ant-design/icons';
import AdminOverviewSubPage from './admin/AdminOverviewSubPage';
import AdminUsersSubPage from './admin/AdminUsersSubPage';
import AdminHousesSubPage from './admin/AdminHousesSubPage';
import AdminInstancesSubPage from './admin/AdminInstancesSubPage';
import AdminBotsSubPage from './admin/AdminBotsSubPage';

const styles: StyleBundle = {
  container: {
  },
  content: {
    marginLeft: '200px',
    padding: '24px',
  },
  contentNarrow: {
    marginLeft: 0,
    padding: '8px',
  },
  subPage: {
    padding: '24px',
  },
  sider: {
    background: '#fff',
    height: '100vh',
    position: 'fixed',
    left: 0,
    zIndex: 2,
  },
  trigger: {
    top: 'auto',
    bottom: '200px',
  },
  menu: {
    paddingTop: '24px',
    borderRight: 0,
  },
};

function AdminPage({ location, match }: RouteComponentProps) {
  const [isNarrow, setIsNarrow] = useState(false);
  
  const selectedRoute = () => {
    const parts = location.pathname.match(/\/admin\/(.+)/i) || ['', 'overview'];
    return [ parts[1] ];
  };

  return (
    <Layout style={styles.container}>
      <Layout.Sider style={styles.sider} breakpoint="md" collapsedWidth="0" onBreakpoint={setIsNarrow} zeroWidthTriggerStyle={styles.trigger}>
        <Menu style={styles.menu} mode="inline" selectedKeys={selectedRoute()}>
          <Menu.Item key="overview" icon={<DashboardOutlined />}>
            <Link to={`${match?.url}`}>Overview</Link>
          </Menu.Item>
          <Menu.Item key="user" icon={<TeamOutlined />}>
            <Link to={`${match?.url}/user`}>Users</Link>
          </Menu.Item>
          <Menu.Item key="house" icon={<HomeOutlined />}>
            <Link to={`${match?.url}/house`}>Houses</Link>
          </Menu.Item>
          <Menu.Item key="bot" icon={<RobotOutlined />}>
            <Link to={`${match?.url}/bot`}>Bots</Link>
          </Menu.Item>
          <Menu.Item key="instance" icon={<UnorderedListOutlined />}>
            <Link to={`${match?.url}/instance`}>Instances</Link>
          </Menu.Item>
        </Menu>
      </Layout.Sider>
      <Layout.Content style={isNarrow ? styles.contentNarrow : styles.content}>
        <Route exact path={`${match?.path}/`}>
          <AdminOverviewSubPage />
        </Route>
        <Route exact path={`${match?.path}/user`}>
          <AdminUsersSubPage />
        </Route>
        <Route exact path={`${match?.path}/house`}>
          <AdminHousesSubPage />
        </Route>
        <Route exact path={`${match?.path}/bot`}>
          <AdminBotsSubPage />
        </Route>
        <Route exact path={`${match?.path}/instance`}>
          <AdminInstancesSubPage />
        </Route>
      </Layout.Content>
    </Layout>
  );
}

export default AdminPage;
