import React from 'react';
import { Card, Collapse, Descriptions } from 'antd';
import { BotEntity } from '../../firebase/bot';
import BotStatusCard from '../../components/bot/BotStatusCard';
import BotInfoHeader from '../../components/bot/BotInfoHeader';
import { CSSProperties } from 'react';
import CardContainer from '../../components/common/CardContainer';

interface BotOverviewSubPageProps {
  bot: BotEntity,
};

const style: CSSProperties = {
  width: '350px',
};

function BotOverviewSubPage({ bot }: BotOverviewSubPageProps) {
  return (
    <>
      <BotInfoHeader bot={bot} />
      <CardContainer>
        <BotStatusCard bot={bot} />
        <Card title="Game Info" style={style}>
          <Descriptions column={1}>
            <Descriptions.Item key="server" label="Minecraft Server">{bot.server || '(Not Set)'}</Descriptions.Item>
            <Descriptions.Item key="authType" label="AccountType">{bot.authType === 'microsoft' ? 'Microsoft' : 'Mojang'}</Descriptions.Item>
            <Descriptions.Item key="username" label="Username">{bot.username || '(Not Set)'}</Descriptions.Item>
            <Descriptions.Item key="password" label="Password">{bot.password ? '******' : '(Not Set)'}</Descriptions.Item>
          </Descriptions>
          {bot.options && (
            <Collapse ghost>
              <Collapse.Panel header="Bot Options" key="1">
                <Descriptions column={1}>
                  {Object.keys(bot.options).map(name => (
                    <Descriptions.Item key={name} label={name}>{bot.options[name]}</Descriptions.Item>
                  ))}
                </Descriptions>
              </Collapse.Panel>
            </Collapse>
          )}
        </Card>
      </CardContainer>
    </>
  );
}

export default BotOverviewSubPage;
