import React, { useState, useEffect } from 'react';
import { Card, List, Modal, PageHeader, Skeleton, Avatar, Statistic, Button } from 'antd';
import { listHouses } from '../../firebase/admin';
import { DisconnectOutlined, HomeOutlined } from '@ant-design/icons';

function AdminHousesSubPage() {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ houses, setHouses ] = useState([] as any[]);

  const loadData = () => {
    setIsLoading(true);
    listHouses()
      .then(houses => {
        setHouses(houses);
        setIsLoading(false);
      })
      .catch(({ message }) => Modal.error({ content: message }));
  };

  useEffect(() => loadData(), []);
 
  return (
    <>
      <PageHeader title="All Houses" extra={<Button type="primary" loading={isLoading} disabled={isLoading} onClick={loadData}>Refresh</Button> } />
      {isLoading && houses.length === 0 && <Skeleton avatar active />}
      {(!isLoading || houses.length > 0) && (
        <Card>
          <List dataSource={houses} renderItem={house => (
            <List.Item
              key={house.id}
              actions={[
                <Statistic title="Load Average" value={house.system.loadAverage?.join(', ')} />,
                <Statistic title="Capacity" value={`${house.capacity.available}/${house.capacity.total}`} />,
                <Statistic title="Selected" value={house.selectedTimes} />,
                <Statistic title="Uptime" value={house.system.houseUptime / 3600} precision={1} suffix="h" />,
                <Statistic title="Memory Usage" value={house.system.memoryUsage?.heapUsed / 1024 / 1024} precision={1} suffix="M" />,
                <Statistic title="Free Memory" value={house.system.freeMemory / 1024 / 1024} precision={1} suffix="M" />,
                <Statistic title="Total Memory" value={house.system.totalMemory / 1024 / 1024} precision={1} suffix="M" />,
              ]}
            >
              <List.Item.Meta
                avatar={<Avatar
                  shape="square"
                  size="large"
                  style={{
                    ...(house.isAlive ? {} : { backgroundColor: '#ff4d4f' })
                  }}
                  icon={house.isAlive ? <HomeOutlined /> : <DisconnectOutlined />}
                />}
                title={`${house.id} @ ${house.system.version}`}
                description={`[${house.location}] ${house.url}`}
              />
            </List.Item>
          )} />
        </Card>
      )}
    </>
  );
}

export default AdminHousesSubPage;
