import { getFunctions, getAnalytics } from './';

export function listUsers() {
  const listUsers = getFunctions().httpsCallable('listUsers');
  getAnalytics().logEvent('list_users');
  return listUsers({}).then(({ data }) => data);
}

export function listHouses() {
  const listHouses = getFunctions().httpsCallable('listHouses');
  getAnalytics().logEvent('list_houses');
  return listHouses({}).then(({ data }) => data);
}

export function listBots() {
  const listBots = getFunctions().httpsCallable('listBots');
  getAnalytics().logEvent('list_bots');
  return listBots({}).then(({ data }) => data);
}

export function listInstances() {
  const listInstances = getFunctions().httpsCallable('listInstances');
  getAnalytics().logEvent('list_instances');
  return listInstances({}).then(({ data }) => data);
}

export function getStatistics() {
  const getStatistics = getFunctions().httpsCallable('getStatistics');
  getAnalytics().logEvent('get_statistics');
  return getStatistics({}).then(({ data }) => data);
}
