import { CSSProperties } from 'react';
import { Button, Card, Typography } from 'antd';
import { StyleBundle } from '../../helpers/types';

interface AuthPendingCardProps {
  authPending: any;
  onRefresh: () => void;
  style?: CSSProperties;
}

const styles: StyleBundle = {
  header: {
    backgroundColor: '#fff1b8',
  },
  code: {
    textAlign: 'center',
    fontSize: '30px',
    lineHeight: '50px',
  }
};

export default function AuthPendingCard({ authPending, onRefresh, style = {} }: AuthPendingCardProps) {
  return (
    <Card title="Action Required" style={style} headStyle={styles.header}>
      <Typography.Title level={4}>Your Bot needs your help to sign in to the game:</Typography.Title>
      <Typography.Paragraph>
        Due to complicated Microsoft login process, please open the Microsoft link page {' '}
        <Typography.Link href={authPending.verificationUri} target="_blank">{authPending.verificationUri}</Typography.Link>
        {' '} and enter the following code to complete authentication:
      </Typography.Paragraph>
      <Typography.Paragraph style={styles.code}>
        <Typography.Text code strong>
          {authPending.userCode}
        </Typography.Text>
      </Typography.Paragraph>
      <Typography.Paragraph>
        It will appear as <Typography.Text strong>Office365APIEditor</Typography.Text>.
        When you done, you can manually refresh the page or click this button to {' '}
        <Button size="small" type="primary" onClick={onRefresh}>Refresh</Button>
      </Typography.Paragraph>
    </Card>
  );
};
