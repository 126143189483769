import React, { createContext, useEffect, useState } from 'react';
import FullPageLoading from '../components/common/FullPageLoading';
import { getAnalytics, getAuthService } from '../firebase';

export interface UserState {
  uid: string;
  displayName: string;
  email: string;
  emailVerified: boolean;
  refreshToken: string;
  isAdmin?: boolean;
}

interface IAuthContext {
  // signIn and signUp are not being used for now since
  // we are leveraging firebaseui
  signIn: (username: string, password: string) => Promise<any>;
  signUp: (username: string, password: string) => Promise<any>;
  signOut: () => Promise<void> | undefined;
  userState: UserState;
}

export const AuthContext = createContext({} as IAuthContext);

export const AuthProvider = (props: any) => {
  const [ userState, setUserState ] = useState(null as any);
  const [ authPending, setAuthPending ] = useState(true);
  const auth = getAuthService();

  const signIn = (username: string, password: string) => {
    return auth.signInWithEmailAndPassword(username, password);
  }

  const signUp = (username: string, password: string) => {
    return auth.createUserWithEmailAndPassword(username, password);
  }

  const signOut = () => auth.signOut();

  useEffect(() => {
    return getAuthService().onAuthStateChanged((userAuth) => {
      if (userAuth) {
        const user = userAuth as UserState;
        // for tracking
        getAnalytics().setUserId(user.uid);
        // get roles
        userAuth.getIdTokenResult().then(({ claims }) => {
          user.isAdmin = Boolean(claims.admin);
          setUserState(user);
          setAuthPending(false);
        });
      } else {
        setUserState(userAuth);
        setAuthPending(false);
      }
    });
  }, []);

  if (authPending) {
    return (
      <FullPageLoading />
    );
  }

  return (
    <AuthContext.Provider value={{
      signIn,
      signUp,
      signOut,
      userState,
    }}>
      {props.children}
    </AuthContext.Provider>
  );
};
