import React, { useState, useEffect, useCallback } from 'react';
import { Button, Card, Empty, Modal, Skeleton } from 'antd';
import { BotEntity, viewBotLogs } from '../../firebase/bot';
import BotInfoHeader from '../../components/bot/BotInfoHeader';
import { getAnalytics } from '../../firebase';
import BotLogs, { LogEntry } from '../../components/bot/BotLogs';

interface BotHistorySubPageProps {
  bot: BotEntity;
}

function BotHistorySubPage({ bot }: BotHistorySubPageProps) {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ logs, setLogs ] = useState([] as LogEntry[]);

  const getLogs = useCallback(() => {
    setIsLoading(true);
    viewBotLogs(bot.id)
      .then(logs => setLogs(logs.slice(-100)))
      .catch(error => Modal.error({ content: error }))
      .finally(() => setIsLoading(false));
  }, [ bot ]);

  useEffect(() => {
    getAnalytics().logEvent('view_bot_history');
    getLogs();
  }, [ bot, getLogs ]);

  return (
    <div>
      <BotInfoHeader bot={bot} actions={[
        <Button type="primary" loading={isLoading} onClick={getLogs}>Refresh</Button>
      ]} />
      {isLoading ? <Skeleton active /> : (
        <Card title="Running History">
          {logs.length === 0 && <Empty />}
          {logs.length > 0 && <BotLogs logs={logs} reverse />}
        </Card>
      )}
    </div>
  );
}

export default BotHistorySubPage;
